<template>
  <y_layout>
    <YRouteTabs :menu="[
        {title:'基础配置',path:{name:'systemInfo',params:$route.params}},
        {title:'远程附件',path: {name:'systemAttach',params:$route.params}},
        {title:'首页轮播',path: {name:'homeBanner',params:$route.params}},
        // {title:'首页菜单',path: {name:'homeTabbar',params:$route.params}},
    ]"></YRouteTabs>
    <div style="margin-top: .5rem"><router-view/></div>
<!--    <el-row>-->
<!--      <el-col :span="1">-->
<!--        <div class="flex-def flex-zEnd" style="width: 100%;margin-top: 2.5rem">-->
<!--          <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom tab-box">-->
<!--            <div :class="$route.name === 'systemInfo' ? 'active' : ''" @click="$router.push({name:'systemInfo',params:$route.params})" class="y-pointer" style="padding: 1rem 0;">基<br>础<br>配<br>置</div>-->
<!--            <div :class="$route.name === 'systemAttach' ? 'active' : ''" @click="$router.push({name:'systemAttach',params:$route.params})" class="y-pointer" style="padding: 1rem 0;">远<br>程<br>附<br>件</div>-->
<!--            <div :class="$route.name === 'homeBanner' ? 'active' : ''" @click="$router.push({name:'homeBanner',params:$route.params})" class="y-pointer" style="padding: 1rem 0;border-top: 1px solid #ededed">首<br>页<br>轮<br>播</div>-->
<!--            <div :class="$route.name === 'homeTabbar' ? 'active' : ''" @click="$router.push({name:'homeTabbar',params:$route.params})" class="y-pointer" style="padding: 1rem 0;border-top: 1px solid #ededed">首<br>页<br>菜<br>单</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-col>-->
<!--      <el-col :span="23">-->
<!--                <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;min-height: 35rem">-->
<!--        <router-view/>-->
<!--                </el-card>-->
<!--      </el-col>-->
<!--    </el-row>-->
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import YRouteTabs from "@/components/YTabs/YRouteTabs";

export default {
  name: "index",
  components: {YRouteTabs, y_layout},
}
</script>

<style scoped>

.y-pointer:hover,
.active {
  color: #5D60F6;
}

.tab-box {
  border-radius: 10px 0 0 10px;
  border: 1px solid #ededed;
  font-size: 12px;
  background-color: #FFFFFF;
  width: 2rem;
  color: #888888;
  font-weight: 600;
}
</style>